<script setup>
const props = defineProps({
    links: Array,
});
const emit = defineEmits(['setPage']);

const getPage = (url) => {
    const params = new URLSearchParams(url.split('?')[1]);

    return params.get('page');
};
</script>
<template>
    <div v-if="links.length > 3">
        <div class="flex flex-wrap -mb-1">
            <template v-for="(link, key) in links">
                <div
                    v-if="link.url === null"
                    :key="key"
                    class="px-4 py-3 mb-1 mr-1 text-sm leading-4 text-gray-500-400 border border-gray-500-200 rounded"
                    v-html="link.label"
                />
                <button
                    v-else
                    :key="`link-${key}`"
                    class="flex items-center justify-center px-4 py-3 mb-1 mr-1 text-sm leading-4 transition-colors bg-white rounded hover:text-white hover:bg-green-500 focus:border-green-500"
                    :class="{
                        'bg-green-500 text-white': link.active,
                        'w-10 h-10': key !== 0 && key !== links.length - 1,
                    }"
                    :href="link.url"
                    v-html="link.label"
                    preserve-state
                    preserve-scroll
                    @click="emit('setPage', getPage(link.url) || 1)"
                ></button>
            </template>
        </div>
    </div>
</template>
